<template>
  <div>
    <!-- 专利任务编辑 -->
    <div>
      <Header back="返回" title="专利任务" index="首页" titleOne="工程专利" titleTwo="专利任务" beforeTitle="任务编辑" />
      <div class="content">
        <h1>基本信息</h1>
        <el-form ref="form" :model="patentForm" label-width="80px" label-position="top">
          <el-form-item label="企业名称">
            <el-input v-model="patentForm.company_name" placeholder="请输入案卷管理数据中已有专利的企业名称"></el-input>
          </el-form-item>
          <el-form-item label="专利名称">
            <el-input v-model="patentForm.patent_name" placeholder="请输入案卷管理数据中已有专利的专利名称"></el-input>
          </el-form-item>
          <el-form-item label="专利号/申请号">
            <el-input v-model="patentForm.app_code" placeholder="请输入案卷管理数据中已有专利的专利号/申请号"></el-input>
          </el-form-item>
        </el-form>
        <div class="foot_TJ">
          <el-button type="primary" :loading="btnLoading" @click="onSubmit()">确定</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      btnLoading: false,//提交按钮动画
      patentForm: {},
    }
  },
  created () {
    if (this.$route.query.id) {
      this.getView()
    }
  },
  methods: {
    getView () {
      this.axios.get('/api/project_patent/view_middle_info', { params: { patent_id: this.$route.query.id } }).then((res) => {
        this.patentForm = res.data
      })
    },
    onSubmit () {
      this.btnLoading = true
      this.axios.post('/api/project_patent/store_mission', { ...this.patentForm }).then((res) => {
        if (res) {
          this.$message.success(res.message)
          this.$router.go(-1)
          this.btnLoading = false
        } else {
          this.$message.error()
          this.btnLoading = false
        }
      }).catch((err) => {
        this.$message.error(err.message)
        this.btnLoading = false
      })
    },
    querySearchAsync (queryString, cb) {
      clearTimeout(this.timeout);
      var results = [];
      if (queryString == "") {
        cb(results);
      } else {
        this.axios
          .get("/api/company/select_company", {
            params: {
              keywords: queryString,
            },
          })
          .then((res) => {
            if (res.message == "查询成功") {
              for (let i = 0; i < res.data.length; i++) {
                const element = res.data[i];
                // console.log(element);
                results.push({
                  value: element.company_name,
                  id: element.id,
                });
                // console.log(results);
              }
              cb(results);
            } else {
              results = [];
              cb(results);
            }
          });
      }
    },
    //点击出现搜索后点击的每一项
    handleSelect (item) {
      // console.log(item);
      this.patentForm.com_id = item.id;
      this.patentForm.company_name = item.value;
    },
  },
}
</script>

<style scoped>
.el-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.el-form-item {
  width: 24%;
  display: inline-block;
  margin-right: 1%;
}
</style>